.demo-ruleForm1 {
  position: relative;
}
.demo-ruleForm1 .ckbjmx {
  position: absolute;
  left: 900px;
}
.demo-ruleForm1 .el-form-item {
  width: 520px;
}
.demo-ruleForm1 .ts .el-form-item__label {
  font-size: 17px;
  font-weight: 600;
}
.demo-ruleForm1 .btbzWitch {
  width: 100%;
}
.demo-ruleForm1 .btbzWitch .el-table__header-wrapper th {
  padding: 0;
  background: #5c6be8;
  color: #fff;
  border-color: #5c6be8;
}
.demo-ruleForm1 .tsWidth {
  width: 100%;
}
.demo-ruleForm1 .tsWidth .el-input {
  width: 280px;
  float: left;
}
.demo-ruleForm1 .sfbzWidth {
  min-width: 1100px;
}
.demo-ruleForm1 .operation {
  width: 800px;
}
.demo-ruleForm1 .operation .el-button {
  margin: 0 15px;
}
.demo-ruleForm1 .tb-edit .el-input {
  display: none;
}
.demo-ruleForm1 .tb-edit .current-row .el-input {
  display: block;
}
.demo-ruleForm1 .tb-edit .current-row .el-input + span {
  display: none;
}
.demo-ruleForm1 .tb-edit .current-row .el-select + span {
  display: none;
}
.demo-ruleForm1 /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm1 /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
.el-textarea .el-textarea__inner {
  min-height: 16rem !important;
  resize: none;
}
.preview-857 .el-table__header-wrapper th {
  padding: 0;
  background: #5c6be8;
  color: #fff;
  border-color: #5c6be8;
}
